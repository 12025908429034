<template>
    <modal ref="modalRegistrarTransferencia" :cargando="loading" titulo="Detalle del pago" no-aceptar adicional="Registrar" @adicional="onRegistrar()">
        <div class="row mx-0">
            <div class="col-auto px-2" />
            <div class="col px-2">
                <div class="justify-center mb-4 mx-0 row">
                    <div class="col-10">
                        <p>Comentario</p>
                        <el-input v-model="comentario" type="textarea" rows="3" maxlength="250" show-word-limit placeholder="Deja un comentario" />
                    </div>
                </div>
                <div class="justify-center mb-4 mx-0 row">
                    <div class="col-10">
                        <div class="mx-0 justify-center my-3">
                            <p>Imagen del recibo de transferencia</p>
                            <slim-cropper
                            ref="imagen"
                            :options="slimOptions"
                            class="border cr-pointer leechero-picture"
                            />
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mb-4">
                    <div class="col d-flex justify-center text-center">
                        {{ pago.observacion }}
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import pagos from '~/services/pagos/pagos'
export default {
    props:{
        idPedido: {
            type: Number,
            default: 1,
        }
    },
    data(){
        return {
            pago: {},
            comentario: '',
            loading: false,
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
        }
    },
    methods: {
        async toggle(){
            this.comentario = ''
            this.$refs.imagen.instanciaCrop.remove()
            this.$refs.modalRegistrarTransferencia.toggle();
        },
        async onRegistrar(){
            try {
                
                this.loading = true
                let imagen = this.$refs.imagen.instanciaCrop.dataBase64.output.image
                if(!imagen) return this.notificacion('', 'La imagen es obligatoria', 'warning')
                let model = {
                    comentario: this.comentario,
                    imagen,
                    admin_pedido: this.idPedido
                }

                let { data } = await pagos.guardarTransferencia(model)
                if(data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    this.$emit('saved')
                    this.toggle()
                }
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
            
        }
    },
}
</script>
<style lang="css" scoped>
.aprobado{
    background-color: #27D07B;
    color: #ffffff;
}
.pendiente{
    background-color: #F5F5F5;
    color: #707070;
}
.cancelado{
    background-color: #FF3B63;
    color: #ffffff;
}
</style>
